.sign-in-page{
    display: flex;
    justify-content: center;
    .form-group{
        .form-control{
            width: fit-content !important;
        }
    }
    .header{
        text-transform: none;
        color:inherit;
        margin-bottom: 30px;
    }
    button{
        margin:10px;
    }
}