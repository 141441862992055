.user-orders-page{
    height:100%;
    .orders-list-wrap{
        height: 100%;
        .orders-list{
            height: 100%;
            overflow-y: scroll;
            .orders-list-item{
                .order-item-wrap{
                    padding:5px;
                    .order-item{
                        border:1px solid #D5D9D9;
                        border-radius: 10px;
                        .header-row{
                            border-bottom: 1px solid #D5D9D9;
                            color:#565959;
                            background-color: #F0F2F2;
                            display: flex;
                            padding:10px;
                            text-align: left;
                            .order-placed{
                                width: 50%;
                                .heading{
                                    font-size: small;
                                }
                            }
                            .order-id-status-wrap{
                                width: 50%;

                                .order-id-wrap{
                                    .heading{
                                        font-size: small;
                                        display: flex;
                                        text-align:center; 
                                        p{
                                            margin-top: auto;
                                            margin-bottom: auto;
                                            .order-id{
                                                font-size: medium;
                                            }
                                        }
                                    }
                                }
                                .order-status-wrap{
                                    .heading{
                                        font-size: small;
                                        display: flex;
                                        text-align:center; 
                                        p{
                                            margin-top: auto;
                                            margin-bottom: auto;
                                        }
                                        .order-status{
                                            font-size: medium;
                                            text-transform: uppercase;
                                            color:green;
                                            width: fit-content;
                                            .dropdown-toggle{
                                                margin-left: 10px;
                                                word-wrap: break-word;
                                                white-space: normal;
                                                padding:4px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .content{
                            text-align: left;
                            padding: 10px;
                            display: flex;
                            .name-phone-numbers-wrap{
                                width: 50%;
                                .name-wrap{
                                    p{
                                        word-wrap: break-word;
                                    }
                                }
                                
                            }
                            .address-wrap{
                                width: 50%;
                                .address-content{
                                    p{
                                        
                                        white-space: pre-wrap;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    
    @media (min-width: 767px) {
        .orders-list-wrap{
            width: 70%;
            margin-left: auto;
            margin-right:auto;
        }
    }
}