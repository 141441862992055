.status-list{
    width: 100%;
    height: 100%;
    .heading{
        height:35px;
    }
    .status-list-wrap{
        height: calc( 100% - 74px);
        overflow-y: scroll;
        width: 100%;
        text-align: left;
        li{
            display: flex;
        }
        .status-name-wrap{
            
            width: calc( 100% - 35px );
        }
        .delete-btn{
            width: 35px;
        }
    }
    .add-new-wrap{
        height:35px;
        display: flex;
        .status-input-wrap{
            width: 75%;
        }
        .add-btn-wrap{
            width: 25%;
        }
    }
}