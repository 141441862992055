.App {
  text-align: center;
  height:100vh;
  .router-wrap{
    height: 100%;
    overflow: hidden;
    .top-nav{
      height:56px;
      position: sticky;
      top:0px;
      z-index: 1;
    }
    .pages-wrap{
      height: calc( 100% - 56px);
    }
  }
}
