.settings-page{
    height: 100%;
    .admin-list-status-list{
        overflow-y: scroll;
        height: 100%;
        display: flex;
        .admin-users{
            height:300px;
            width: 300px;
            min-width: 300px;
            border:1px solid rgb(211, 211, 211);
            border-radius: 10px;
            margin-left: auto;
            margin-right:auto;
        }
        .statuses-wrap{
            height:300px;
            width: 300px;
            min-width: 300px;
            border:1px solid rgb(211, 211, 211);
            border-radius: 10px;
            margin-left: auto;
            margin-right:auto;
        }
    }

    @media (max-width: 767px) {
        
        .admin-list-status-list{
            width: 100%;
            margin-left: auto;
            margin-right:auto;
            display: block;
            .admin-users{
                height:300px;
                width: 100%;
                min-width: 300px;
                border:1px solid rgb(211, 211, 211);
                border-radius: 10px;
                margin-left: auto;
                margin-right:auto;
            }
            .statuses-wrap{
                width: 100%;
            }
        }
    }
}